import { AgGridReact } from 'ag-grid-react';
import React, {
  ReactElement, useState, useRef, useCallback,
} from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { formatDate, formatDateWithMonthName } from '../../common_lib_front/utilities/formatDate';
import dataGridStyle from './companyDocumentsGrid.module.css';
import PopupCellRenderer from './popupCellRenderer';
import { CompanyDocument } from '../companyDocumentTable/companyDocument';

type CompanyDocumentsGridProps = {
  data: CompanyDocument[],
};

function makePastDateUTC(date: Date, days: number): Date {
  const copy = new Date(date.valueOf());
  copy.setDate(copy.getDate() - days);
  copy.setUTCHours(0, 0, 0, 0);
  return copy;
}

// row wise data for the grid
export default function CompanyDocumentsGrid(props: CompanyDocumentsGridProps): ReactElement {
  const {
    data,
  } = props;
  const gridRef = useRef<AgGridReact>(null); // for accessing Grid's API
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const companyDocumentsRowData = data.map((elem: CompanyDocument) => ({
    // eslint-disable-next-line max-len
    expired: elem.documentExpiration ? (makePastDateUTC(new Date(elem.documentExpiration), 0).getTime() < makePastDateUTC(new Date(), 0).getTime()) : false,
    fileName: elem.fileName || '',
    fileType: elem.documentType || '',
    userDocumentName: elem.userDocumentName || '',
    uploadDate: elem.uploadDate || '',
    passNumber: elem.passNumber || '',
    documentExpiration: elem.documentExpiration || '',
    actions: elem,
  }));

  // Date value formatter
  const dateFormatter = ({ value }: { value: string }) => formatDate(new Date(value));

  const filterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      const dateAsString = cellValue;
      const dateParts = dateAsString.split('-');
      const cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1]) - 1,
        Number(dateParts[2]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  // DefaultColDef sets props common to all Columns
  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    wrapHeaderText: true,
  };

  // Columns and its props
  const [employeesColumnDefs] = useState([
    {
      headerName: '',
      hide: true, // Todo: enable for bulk action selection
      field: 'bulkselect',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      editable: false,
      sortable: false,
      filter: false,
      minWidth: 50,
      maxWidth: 50,
    },
    {
      headerName: 'Document Name',
      field: 'userDocumentName',
      minWidth: 100,
    },
    {
      headerName: 'File Type', field: 'fileType', minWidth: 100, flex: 2,
    },
    {
      headerName: 'Upload Date',
      field: 'uploadDate',
      filter: 'agDateColumnFilter',
      filterParams,
      valueFormatter: dateFormatter,
      minWidth: 100,
    },
    {
      headerName: 'Expiration Date',
      field: 'documentExpiration',
      filter: 'agDateColumnFilter',
      filterParams,
      valueFormatter: dateFormatter,
      minWidth: 100,
    },
    {
      headerName: '',
      hide: false,
      pinned: true,
      field: 'actions',
      cellRenderer: PopupCellRenderer,
      cellRendererParams: {},
      editable: false,
      sortable: false,
      filter: false,
      width: 150,
    },
    {
      headerName: 'Status',
      field: 'expired',
      pinned: true,
      // eslint-disable-next-line no-shadow
      filterValueGetter: (params: any) => (params.data.expired ? 'Suspended' : 'Active'),
      cellRendererFramework: ({ value }: { value: string }) => (
        <div className={`${dataGridStyle.gridStatusBox} ${value ? dataGridStyle.expired : dataGridStyle.active}`}>
          {value ? 'Suspended' : 'Active'}
        </div>
      ),
    },
  ]);

  // Export to CSV
  const onBtnExport = useCallback(() => {
    const columnKeys = ['fileName', 'fileType', 'uploadDate', 'documentExpiration'];
    const headers: string[] = [];
    const allNodes: any[] = [];
    employeesColumnDefs.slice(1).forEach((col) => {
      headers.push(col.headerName);
    });
    const dataToExport: string[] = [headers.join(',')];
    gridRef.current?.api.forEachNode((row) => {
      if (row.displayed) allNodes.push(row);
    });
    allNodes
      .sort((a, b) => a.rowIndex - b.rowIndex)
      .forEach((row) => {
        const { data: item } = row;
        const itemData: any[] = [];
        columnKeys.forEach((key: string) => {
          if (key === 'uploadDate' || key === 'documentExpiration') {
            itemData.push(formatDateWithMonthName(new Date(item[key])));
          } else {
            itemData.push(`"${item[key]}"`);
          }
        });
        dataToExport.push(itemData.join(','));
      });
    const str = dataToExport.join('\r\n');

    const blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'export.csv');
    pom.click();
  }, []);
  // Quick filter event handler
  const onFilterTextChange = (e: { target: { value: string } }) => {
    gridRef.current?.api.setQuickFilter(e.target.value);
  };

  return (
    <div>
      <div className={dataGridStyle.topLine}>
        <input
          type="search"
          className={dataGridStyle.gridSearch}
          onChange={onFilterTextChange}
          placeholder="Search anything here .."
        />

        <button className={dataGridStyle.btnExport} onClick={onBtnExport}>Export CSV</button>
      </div>
      <div className={dataGridStyle.paginationBox}>
        <label htmlFor="page-num-inpt">
          Show
          <select
            className={dataGridStyle.selectBox}
            value={itemsPerPage}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setItemsPerPage(Number.parseInt(e.target.value, 10));
            }}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          Per Page
        </label>
      </div>
      <div className={dataGridStyle.radiusBox}>
        <div
          className="ag-theme-alpine"
          style={{
            height: '100%',
            width: '100%',
            overflow: 'scroll',
          }}
        >
          <AgGridReact
            rowData={companyDocumentsRowData}
            columnDefs={employeesColumnDefs}
            defaultColDef={defaultColDef}
            enableCellTextSelection
            ensureDomOrder
            animateRows
            pagination
            paginationPageSize={itemsPerPage}
            // paginationAutoPageSize
            ref={gridRef}
          />
        </div>
      </div>
    </div>
  );
}
