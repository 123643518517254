import { gql, useMutation, useQuery } from '@apollo/client';
import React, {
  ChangeEvent,
  ReactElement,
  useContext,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CommunityContext } from '../../../common_lib_front/communityConfigs/communityContextProvider';
import GenericAlert from '../../../common_lib_front/components/genericAlert/genericAlert';
import GenericButton from '../../../common_lib_front/components/genericButton/genericButton';
import InputField from '../../../common_lib_front/components/inputField/inputField';
import PhoneInputWrapper from '../../../common_lib_front/components/phoneInputWrapper/phoneInputWrapper';
import EmployeeInfo from '../../../common_lib_front/types/employeeInfo';
import { PopUp } from '../../../components/popUp/popUp';
import { GET_EMPLOYEE } from '../../company/employees/employees';
import style from './editEmployee.module.css';
import { IdentificationTypes } from '../../../common_lib/types/IdentificationTypes';
import { formatDateForInput } from '../../../common_lib_front/utilities/formatDate';

export const EDIT_EMPLOYEE = gql`
mutation editEmployee (
  $employeeId: String!
  $data: EmployeeInput!
) {
  editEmployee (
    employeeId: $employeeId
    data: $data
  ) {
    success
    error
  }
}
`;

export default function EditEmployee(): ReactElement {
  const [employeeInfo, setEmployeeInfo] = useState<EmployeeInfo>();
  // const [originalEmployeeInfo, setOriginalEmployeeInfo] = useState<EmployeeInfo>();
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const history = useHistory();
  const { employeeId } = useParams<{ employeeId: string }>();
  const { communityId } = useContext(CommunityContext);

  const { loading } = useQuery(GET_EMPLOYEE, {
    variables: {
      employeeId,
    },
    fetchPolicy: 'cache-and-network',
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (!d.getEmployee.success) {
        setAlert(d.getEmployee.error);
        setAlertColor('red');
      } else {
        setEmployeeInfo({
          ...d.getEmployee.data[0],
          file: '',
          fileName: '',
          // eslint-disable-next-line max-len
          driverLc: d.getEmployee.data[0].identificationNumber || d.getEmployee.data[0].driversLicenseNum,
          driverLcExp: d.getEmployee.data[0].driversLicenseExp?.substring(0, 10),
        });
        // setOriginalEmployeeInfo(d.getEmployee.data[0]);
      }
    },
  });
  const myEmpInputRef = useRef<HTMLInputElement[]>([]);

  myEmpInputRef.current = [];
  const addToEmpRefs: (el: HTMLInputElement) => void = (el) => {
    if (el && !myEmpInputRef.current.includes(el)) {
      myEmpInputRef.current.push(el);
    }
  };
  const [doSubmit, { loading: submitting }] = useMutation(EDIT_EMPLOYEE, {
    onError: (e) => {
      setAlert(e.message);
      setAlertColor('red');
    },
    onCompleted: (d) => {
      if (!d.editEmployee.success) {
        setAlert(d.editEmployee.error);
        setAlertColor('red');
      } else {
        history.goBack();
      }
    },
  });

  const submitHandler = () => {
    const requiredFields: (keyof EmployeeInfo)[] = ['firstName', 'lastName', 'phoneNumber'];
    if (communityId !== 'palmettodunes') {
      requiredFields.push('email');
    }
    if (requiredFields.some((k) => !employeeInfo?.[k])) {
      setAlert('Please fill out the required info');
      setAlertColor('red');
    } else {
      doSubmit({
        variables: {
          employeeId,
          data: {
            firstName: employeeInfo?.firstName,
            lastName: employeeInfo?.lastName,
            email: employeeInfo?.email || null,
            phoneNumber: employeeInfo?.phoneNumber,
            driversLicenseExp: employeeInfo?.driverLcExp,
            isLicenseSelected: true,
            file: employeeInfo?.file,
            fileName: employeeInfo?.fileName,
            source: employeeInfo?.source,
            identificationNumber: employeeInfo?.identificationNumber,
          },
        },
      });
    }
  };
  const fileToBase64 = function (file: any): Promise<string> {
    return new Promise((resolve: any) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };
  const selectForUpload = async function (event: ChangeEvent<HTMLInputElement>) {
    const f = event?.target?.files?.[0];
    if (!f) return;
    const url = URL.createObjectURL(f);
    const fileString = await fileToBase64(f);
    // if (employeeInfo?.isLicenseSelected) {
    setEmployeeInfo({
      ...employeeInfo,
      file: fileString,
      driversLicenseImageUrl: url,
      fileName: f.name,
    });
    // }
    // else {
    //   setEmployeeInfo({
    //     ...employeeInfo,
    //     file: fileString,
    //     employeePictureUrl: url,
    //   });
    // }
  };
  return (
    <PopUp
      title="View Employee"
      close={() => history.goBack()}
    >
      <div style={{ width: '85%' }}>

        <form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          submitHandler();
        }}
        >
          <div className={style.flexBox}>
            <div className={style.boxLeft}>

              <div className={style.rowName}>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="f-name-inpt"
                    labelTitle="First Name *"
                    inputType="text"
                    inputValue={employeeInfo?.firstName || ''}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmployeeInfo({ ...employeeInfo, firstName: e.target.value });
                    }}
                  />
                </div>
                <div className={style.inputShort}>
                  <InputField
                    largeSize
                    highlightOnFocus
                    htmlFor="l-name-inpt"
                    labelTitle="Last Name *"
                    inputType="text"
                    inputValue={employeeInfo?.lastName || ''}
                    changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmployeeInfo({ ...employeeInfo, lastName: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className={style.input}>
                <PhoneInputWrapper
                  standardInput
                  inputTitle="Phone Number *"
                  value={employeeInfo?.phoneNumber || ''}
                  changeHandler={(val: string) => {
                    setEmployeeInfo({ ...employeeInfo, phoneNumber: val });
                  }}
                />
              </div>
              <div className={style.input}>

                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="email-inpt"
                  labelTitle={`Email ${communityId !== 'palmettodunes' ? '*' : ''}`}
                  inputType="email"
                  inputValue={employeeInfo?.email || ''}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmployeeInfo({ ...employeeInfo, email: e.target.value });
                  }}
                />
              </div>
              {/* {employeeInfo?.isLicenseSelected
                && ( */}
              <div className={style.input}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="identificationNumber-inpt"
                  labelTitle="Identification Number (e.g. Driver License, Passport, etc.)"
                  inputType="text"
                  inputValue={employeeInfo?.identificationNumber || employeeInfo?.driversLicenseNum || ''}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmployeeInfo({ ...employeeInfo, identificationNumber: e.target.value });
                  }}
                />
              </div>
              {/* )} */}

              <div className={style.input}>
                <InputField
                  largeSize
                  highlightOnFocus
                  htmlFor="driverLcExp-inpt"
                  labelTitle="Expiration Date"
                  inputType="date"
                  inputValue={formatDateForInput(employeeInfo?.driverLcExp) || ''}
                  changeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmployeeInfo({
                      ...employeeInfo,
                      driverLcExp: formatDateForInput(e.target.value),
                    });
                  }}
                />
              </div>
              {/* )} */}

              <div className={style.input}>
                <span className={style.labelFont}>Source</span>
                <div className={style.selectBox}>
                  <select
                    onChange={(e) => {
                      setEmployeeInfo({ ...employeeInfo, source: e.target.value });
                    }}
                    value={employeeInfo?.source || ''}
                    className={style.select}
                  >
                    <option selected disabled hidden>
                      {' '}
                    </option>
                    {
                      Object.entries(IdentificationTypes).map(
                        ([key, val]) => <option value={key}>{val}</option>,
                      )
                    }

                  </select>
                </div>
              </div>

              <GenericAlert
                color={alertColor}
                title={alert}
                hidden={!alert}
              />

            </div>
            <div className={style.boxRight}>
              {
                // eslint-disable-next-line no-nested-ternary
                // employeeInfo?.isLicenseSelected
                //   ?

                employeeInfo?.driversLicenseImageUrl?.trim()
                  ? <img width="100%" height="auto" src={employeeInfo?.driversLicenseImageUrl} alt="Not available" />
                  : (
                    <div className={style.noImage}>
                      <p>Select picture</p>
                    </div>
                  )
                // : employeeInfo?.employeePictureUrl?.trim()
                //   ? <img width="100%" height="auto" src={employeeInfo?.employeePictureUrl} alt="Not available empl" />
                //   : (
                //     <div className={style.noImage}>
                //       <p>Select picture</p>
                //     </div>
                //   )
              }
              <label htmlFor="file-upload" className={style.uploadBtn}>
                Upload/Change Image
                <input
                  id="file-upload"
                  hidden
                  type="file"
                  onChange={selectForUpload}
                  className={style.uploadBtn}
                  accept=".jpg,.jpeg,.png"
                />
              </label>
            </div>
          </div>
          <div className={style.btnBox}>
            <GenericButton
              title="Save Changes"
              type="submit"
              size="large"
              color="blue"
            />
          </div>
        </form>
      </div>
    </PopUp>
  );
}
